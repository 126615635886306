import { PricesData, PricesDataWithFlags } from "./Prices";
import { DiscountData } from "./DiscountRow";
import * as discHelpers from "./DiscountReducerHelpers";

export type DiscountsState = {
  dirty: boolean;
  current: DiscountData[];
  dirtyBackup: boolean;
  backup: DiscountData[];
};

enum ActionType {
  CHANGE_VALUE,

  // discount specific actions
  DISCOUNT_NEW,
  DISCOUNT_RESET_NEW,

  DISCOUNT_DELETE,
  DISCOUNT_EDIT,
  DISCOUNT_SAVE_EDIT,
  DISCOUNT_CANCEL_EDIT,

  DISCOUNT_RECALCULATE,

  DISCOUNT_FORM_HIDE,
  DISCOUNT_FORM_SHOW
}

function extractPropertyNameFromInputName(inputName: string): string {
  if (!inputName) {
    throw new Error("Input name empty");
  }
  return inputName.substring(inputName.lastIndexOf(".") + 1);
}

const pricesReducer = (
  state: PricesDataWithFlags,
  action
): PricesDataWithFlags => {
  if (action.type === ActionType.CHANGE_VALUE) {
    const propName = extractPropertyNameFromInputName(action.inputName);

    if (
      state[propName] === action.inputValue &&
      state.changeConfirmed === action.changeConfirmed
    ) {
      return state;
    } else {
      const changeConfirmed =
        action.changeConfirmed != null ? action.changeConfirmed : undefined;

      return {
        ...state,
        [propName]: action.inputValue,
        changeConfirmed,
        dirty: true
      };
    }
  } else {
    throw new Error("Unknown action: " + action.type);
  }
};

const discountsReducer = (state: DiscountsState, action): DiscountsState => {
  switch (action.type) {
    case ActionType.DISCOUNT_EDIT: {
      return state;
    }

    case ActionType.DISCOUNT_SAVE_EDIT: {
      const newState = discHelpers.cloneDiscountsState(state, true, obj => {
        delete obj.isNew;
        return new DiscountData({ ...obj });
      });

      // update backup
      newState.backup = newState.current;
      newState.dirtyBackup = newState.dirty;
      return newState;
    }

    case ActionType.DISCOUNT_RECALCULATE: {
      const prices: PricesData = action.pricesData;
      const newState = discHelpers.cloneDiscountsState(state, true, obj => {
        const ret = new DiscountData({ ...obj });
        discHelpers.runDiscountDependantPropertyRules(
          ret,
          prices,
          "startPricePercent"
        );
        discHelpers.runDiscountDependantPropertyRules(
          ret,
          prices,
          "monthlyPricePercent"
        );
        return ret;
      });

      return newState;
    }
  }

  const newState = discHelpers.cloneDiscountsState(
    state,
    true,
    obj => new DiscountData({ ...obj })
  );

  switch (action.type) {
    case ActionType.DISCOUNT_NEW: {
      newState.dirty = state.dirty;
      const newDisc = discHelpers.createNewDiscount(action.bindingId);
      newState.current.push(newDisc);
      break;
    }

    case ActionType.DISCOUNT_RESET_NEW: {
      if (action.discRowIndex == null) {
        throw new Error("discRowIndex missing");
      }

      newState.dirty = state.dirty;
      const newDisc = discHelpers.createNewDiscount(
        action.bindingId,
        action.init
      );
      newState.current[action.discRowIndex] = newDisc;
      break;
    }

    case ActionType.CHANGE_VALUE: {
      // update single field
      if (action.discRowIndex == null) {
        throw new Error("discRowIndex missing");
      }

      const propName = extractPropertyNameFromInputName(action.inputName);
      const disc = discHelpers.cloneDiscountDataOnValueChange(
        state.current[action.discRowIndex],
        propName,
        action
      );
      newState.current[action.discRowIndex] = disc;
      break;
    }

    case ActionType.DISCOUNT_DELETE: {
      if (action.discRowIndex == null) {
        throw new Error("discRowIndex missing");
      }

      newState.current.splice(action.discRowIndex, 1);
      // update backup
      newState.backup = newState.current;
      newState.dirtyBackup = newState.dirty;
      break;
    }

    case ActionType.DISCOUNT_CANCEL_EDIT: {
      // restore from backup
      newState.current = newState.backup;
      // nothing changed - keep dirty state
      newState.dirty = state.dirtyBackup;
      return newState;
    }

    default: {
      throw new Error("Unknown action: " + action.type);
    }
  }

  return newState;
};

const discountRowInEditReducer = (
  state: { priceRowIndex: number; discountRowIndex: number } | null,
  action: {
    type: ActionType;
    priceRowIndex: number;
    discountRowIndex: number;
  }
): { priceRowIndex: number; discountRowIndex: number } | null => {
  if (
    state !== null &&
    (state.priceRowIndex !== action.priceRowIndex ||
      (state.discountRowIndex || action.discountRowIndex) !==
        action.discountRowIndex)
  ) {
    throw new Error(
      `Discount in edit cannot be changed from ${JSON.stringify(
        state
      )} to ${JSON.stringify(action)}`
    );
  }

  if (action.type === ActionType.DISCOUNT_FORM_HIDE) {
    return null;
  } else if (action.type === ActionType.DISCOUNT_FORM_SHOW) {
    return {
      priceRowIndex: action.priceRowIndex,
      discountRowIndex: action.discountRowIndex
    };
  } else {
    throw new Error("Unknown action: " + action.type);
  }
};

export { pricesReducer, discountsReducer, discountRowInEditReducer, ActionType };
