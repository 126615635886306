import React from "react";
import { DiscountData } from "./DiscountRow";

export type DiscountsContextType = {
  formatInputName: (
    discRowIndex: number,
    propertyName: keyof DiscountData
  ) => string;
  isEditFormBusy: boolean;
  shouldEditFormBeShown: any;
  discountRowInEditDispatch: any;
};

const DiscountsContext = React.createContext<DiscountsContextType>(null!);

export default DiscountsContext;
