import React, { useContext } from "react";
import PricesContext from "./PricesContext";
import DiscountsContext from "./DiscountsContext";
import DiscountEditForm from "./DiscountEditForm";
import { ActionType } from "./Reducers";

export type DiscountDataWithIsNewFlag = DiscountData & { isNew?: boolean };

export class DiscountData {
  campaignId: number | undefined;
  // use code if the campaign is expired and ID isn't in the discountCampaignIdList
  campaignCode?: string;

  bindingId!: number;

  pricePeriod!: string;

  note!: string;

  startPrice: number | undefined;
  startPricePercent: number | undefined;

  monthlyPrice: number | undefined;
  monthlyPricePercent: number | undefined;

  productName!: string;

  eurobonusPointClassId: number | undefined;
  eurobonusPointClassName: string | undefined;

  public constructor(init?: Partial<DiscountDataWithIsNewFlag>) {
    Object.assign(this, init);
  }

  isEuroBonusDiscount(): boolean {
    return this.eurobonusPointClassId != null;
  }
}

type DiscountRowProps = {
  discRowIndex: number;
  discountData: DiscountDataWithIsNewFlag;
  onEditorAction: React.Dispatch<any>;
};

const floatFormatter = new Intl.NumberFormat("sv-SE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const DiscountRow = (props: DiscountRowProps) => {
  // needed for user-friendly campaign name
  const {
    discountLengthList,
    discountCampaignIdList,
    euroBonusClassList
  } = useContext(PricesContext);

  const {
    formatInputName,
    isEditFormBusy,
    shouldEditFormBeShown,
    discountRowInEditDispatch
  } = useContext(DiscountsContext);

  const showEditor = shouldEditFormBeShown(
    props.discRowIndex,
    props.discountData
  );

  function handleShowEditorClick(event): void {
    discountRowInEditDispatch({
      type: ActionType.DISCOUNT_FORM_SHOW,
      discountRowIndex: props.discRowIndex
    });

    props.onEditorAction({ type: ActionType.DISCOUNT_EDIT });
  }

  function handleSaveChangesClick(event): void {
    event.preventDefault();

    discountRowInEditDispatch({
      type: ActionType.DISCOUNT_FORM_HIDE,
      discountRowIndex: props.discRowIndex
    });

    props.onEditorAction({ type: ActionType.DISCOUNT_SAVE_EDIT });
  }

  function handleCancelChangesClick(event): void {
    event.preventDefault();

    discountRowInEditDispatch({
      type: ActionType.DISCOUNT_FORM_HIDE,
      discountRowIndex: props.discRowIndex
    });

    props.onEditorAction({ type: ActionType.DISCOUNT_CANCEL_EDIT });
  }

  function handleDeleteRowClick(event): void {
    discountRowInEditDispatch({
      type: ActionType.DISCOUNT_FORM_HIDE,
      discountRowIndex: props.discRowIndex
    });

    props.onEditorAction({
      type: ActionType.DISCOUNT_DELETE,
      discRowIndex: props.discRowIndex
    });
  }

  function dispatch(action): void {
    props.onEditorAction({
      ...action,
      discRowIndex: props.discRowIndex
    });
  }

  function formatCampaignDescription() {
    const pricePeriodPair = discountLengthList.find(
      pair => pair[0] === props.discountData.pricePeriod
    );

    let duration = "UNKNOWN";
    if (pricePeriodPair) {
      duration = Number(pricePeriodPair[1])
        ? `${pricePeriodPair[1]} month(s)`
        : pricePeriodPair[1].toUpperCase();
    }

    let line: string = "";

    if (props.discountData.isEuroBonusDiscount()) {
      const ebClassPair = euroBonusClassList.find(
        pair => pair[0] === props.discountData.eurobonusPointClassId
      );
      const ebClassName = ebClassPair
        ? ebClassPair[1]
        : props.discountData.eurobonusPointClassName || "UNKNOWN";
      line = `EUROBONUS "class ${ebClassName}" campaign with ${duration} duration`;
    } else if (
      props.discountData.startPrice != null ||
      props.discountData.monthlyPrice != null
    ) {
      const campCodePair = discountCampaignIdList.find(
        pair => pair[0] === props.discountData.campaignId
      );
      const campCodeName = campCodePair
        ? campCodePair[1]
        : props.discountData.campaignCode || "UNKNOWN";

      if (props.discountData.startPrice != null) {
        const price = floatFormatter.format(props.discountData.startPrice);
        line = `${price} kr start fee discount`;
      }

      if (props.discountData.monthlyPrice != null) {
        const price = floatFormatter.format(props.discountData.monthlyPrice);
        line =
          line !== "" ? `${line} and ${price} kr/m` : `Discount ${price} kr/m`;
        line = `${line} with ${duration} duration - ${campCodeName}`;
      }
    } else {
      return "ERROR: Price data missing";
    }

    if (props.discountData.note) {
      line = `${line} ( ${props.discountData.note} )`;
    }

    return line;
  }

  function renderHiddenField(propertyName: keyof DiscountData) {
    return (
      <input
        type="hidden"
        name={formatInputName(props.discRowIndex, propertyName)}
        value={
          (props.discountData[propertyName] != null
            ? props.discountData[propertyName]
            : "") as string | number | undefined
        }
      />
    );
  }

  return (
    <tr
      className={
        showEditor ? "discount-row discount-row-editing" : "discount-row"
      }
    >
      <td>
        {!props.discountData.isNew && (
          <React.Fragment>
            {renderHiddenField("campaignId")}
            {renderHiddenField("bindingId")}
            {renderHiddenField("pricePeriod")}
            {renderHiddenField("note")}
            {renderHiddenField("startPrice")}
            {/* TODO: show? */}
            {/* {renderHiddenField("startPricePercent")} */}
            {renderHiddenField("monthlyPrice")}
            {/* TODO: show? */}
            {/* {renderHiddenField("monthlyPricePercent")} */}
            {renderHiddenField("productName")}
            {renderHiddenField("eurobonusPointClassId")}
            {/* {renderHiddenField("eurobonusPointClassName")} */}
          </React.Fragment>
        )}

        {!showEditor && (
          <div className="discount-description">
            {formatCampaignDescription()}
          </div>
        )}

        {/* <pre
          style={{
            maxWidth: "500px",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap"
          }}
        >
          {props.discRowIndex}: {JSON.stringify(props.discountData)}
        </pre> */}

        {showEditor && (
          <div>
            <DiscountEditForm
              discRowIndex={props.discRowIndex}
              discountData={props.discountData}
              dispatch={dispatch}
              onSaveChangesClick={handleSaveChangesClick}
              onCancelChangesClick={handleCancelChangesClick}
            />
          </div>
        )}
      </td>
      <td>
        <button
          type="button"
          onClick={handleShowEditorClick}
          disabled={isEditFormBusy}
        >
          Edit
        </button>
        <button
          type="button"
          onClick={handleDeleteRowClick}
          disabled={isEditFormBusy}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default DiscountRow;
