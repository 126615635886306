const dummyInstalmentPeriodList = [1, 2, 3, 4, 5];

const dummyDiscountCampaignIdList = [
  [0, "DEFAULT"],
  [52, "EUROBONUS"],
  [106, "MITTMEDIA2019"],
  [112, "REA99"]
];

const dummyDiscountLengthList = [
  ["CAMPAIGN", "No limit"],
  ["CAMPAIGN1", "1"],
  ["CAMPAIGN2", "2"],
  ["CAMPAIGN3", "3"],
  ["CAMPAIGN4", "4"],
  ["CAMPAIGN5", "5"],
  ["CAMPAIGN6", "6"]
];

const dummyEuroBonusClassList = [
  [1, "A"],
  [2, "B"],
  [3, "C"],
  [4, "D"],
  [5, "E"]
];

const dummyPriceLines = [
  {
    isActive: true,
    isCostPrice: false,

    bindingId: 0,
    startPrice: 80.0,
    monthlyPrice: 120.0,
    instalmentPrice: undefined,
    instalmentPeriod: undefined,
    minimidebPrice: undefined,

    rawDiscounts: [
      {
        campaignId: 0,
        campaignCode: "DEFAULT",
        // bindingId: 0,
        pricePeriod: "CAMPAIGN4",
        // duration: 4,
        startPrice: 24.0,
        startPricePercent: 30.0,
        monthlyPrice: undefined,
        monthlyPricePercent: undefined,
        note: undefined,
        productName: ""
      },
      {
        campaignId: 106,
        campaignCode: "MITTMEDIA2019",
        // bindingId: 0,
        pricePeriod: "CAMPAIGN6",
        // duration: 6,
        startPrice: undefined,
        startPricePercent: undefined,
        monthlyPrice: 36.0,
        monthlyPricePercent: 30.0,
        note: "6MHP",
        productName: ""
      },
      {
        campaignId: 118,
        campaignCode: "5819OU",
        // bindingId: 0,
        pricePeriod: "CAMPAIGN8",
        // duration: 8,
        startPrice: 80.0,
        startPricePercent: 100.0,
        monthlyPrice: 60.0,
        monthlyPricePercent: 50.0,
        note: "8MHP",
        productName: ""
      }
    ]
  },
  // cost price
  {
    isActive: true,
    isCostPrice: true,

    bindingId: 0,
    startPrice: undefined,
    monthlyPrice: undefined,
    instalmentPrice: undefined,
    instalmentPeriod: undefined,
    minimidebPrice: undefined
  },
  {
    isActive: true,
    isCostPrice: false,

    bindingId: 12,
    startPrice: 0.0,
    monthlyPrice: 82.5,
    instalmentPrice: 0.0,
    instalmentPeriod: 1,
    minimidebPrice: undefined
  },
  // cost price
  {
    isActive: true,
    isCostPrice: true,

    bindingId: 12,
    startPrice: undefined,
    monthlyPrice: undefined,
    instalmentPrice: undefined,
    instalmentPeriod: undefined,
    minimidebPrice: undefined
  },
  {
    isActive: true,
    isCostPrice: false,

    bindingId: 24,
    startPrice: 0.0,
    monthlyPrice: 60.0,
    instalmentPrice: 0.0,
    instalmentPeriod: 2,
    minimidebPrice: undefined,

    rawDiscounts: [
      {
        campaignId: 0,
        campaignCode: "DEFAULT",
        // bindingId: 0,
        pricePeriod: "CAMPAIGN4",
        // duration: 4,
        startPrice: undefined,
        monthlyPrice: 45.0,
        monthlyPricePercent: 75.0,
        note: undefined,
        productName: ""
      },
      {
        campaignId: 52,
        campaignCode: "EUROBONUS",
        // bindingId: 0,
        pricePeriod: "CAMPAIGN",
        // duration: 4,
        startPrice: undefined,
        monthlyPrice: undefined,
        monthlyPricePercent: undefined,
        note: undefined,
        productName: "",
        eurobonusPointClassId: 6,
        eurobonusPointClassName: "TEST"
      }
    ]
  },
  // cost price
  {
    isActive: true,
    isCostPrice: true,

    bindingId: 24,
    startPrice: undefined,
    monthlyPrice: undefined,
    instalmentPrice: undefined,
    instalmentPeriod: undefined,
    minimidebPrice: undefined
  },
  {
    isActive: false,
    isCostPrice: false,

    bindingId: 36,
    startPrice: 0.0,
    monthlyPrice: 42.5,
    instalmentPrice: 0.0,
    instalmentPeriod: 3,
    minimidebPrice: undefined
  },
  // cost price
  {
    isActive: false,
    isCostPrice: true,

    bindingId: 36,
    startPrice: undefined,
    monthlyPrice: undefined,
    instalmentPrice: undefined,
    instalmentPeriod: undefined,
    minimidebPrice: undefined
  }
];

export default {
  instalmentPeriodList: dummyInstalmentPeriodList,
  discountCampaignIdList: dummyDiscountCampaignIdList,
  discountLengthList: dummyDiscountLengthList,
  euroBonusClassList: dummyEuroBonusClassList,
  priceLines: dummyPriceLines
};
