import { PricesData } from "./Prices";
import { DiscountDataWithIsNewFlag, DiscountData } from "./DiscountRow";
import { DiscountsState } from "./Reducers";

function roundPriceNumber(price: number) {
  return Number(Math.round(Number(price + "e2")) + "e-2");
}

function calculateDiscountFromPercentage(
  fullPrice: number | undefined,
  percentage: number | undefined
) {
  if (fullPrice == null || percentage == null) {
    return { percentage };
  }

  percentage = Math.min(percentage, 100);
  const amount = roundPriceNumber(fullPrice * (percentage / 100));
  return {
    amount,
    percentage
  };
}

function calculateDiscountFromAmount(
  fullPrice: number | undefined,
  amount: number | undefined
) {
  if (fullPrice == null || amount == null) {
    return {};
  }

  amount = Math.min(fullPrice, amount);
  const percentage =
    amount === fullPrice ? 100 : roundPriceNumber((amount / fullPrice) * 100);
  return {
    amount,
    percentage
  };
}

function cloneDiscountsState(
  state: DiscountsState,
  markAsDirty: boolean,
  cloneStrategy: (obj) => any
): DiscountsState {
  return {
    dirty: markAsDirty || state.dirty,
    current: state.current.map(cloneStrategy),
    dirtyBackup: state.dirtyBackup,
    backup: state.backup.map(cloneStrategy)
  };
}

function createNewDiscount(
  bindingId: number,
  mergeWith?: any
): DiscountDataWithIsNewFlag {
  const newDisc: DiscountDataWithIsNewFlag = new DiscountData({
    isNew: true,
    bindingId: bindingId,
    campaignId: undefined,
    pricePeriod: "",
    startPrice: undefined,
    startPricePercent: undefined,
    monthlyPrice: undefined,
    monthlyPricePercent: undefined,
    note: "",
    productName: "",
    eurobonusPointClassId: undefined,
    eurobonusPointClassName: undefined
  });

  return Object.assign(newDisc, mergeWith);
}

function runDiscountDependantPropertyRules(
  discount: DiscountData,
  prices: PricesData,
  propertyName: string
) {
  switch (propertyName) {
    case "startPrice":
    case "monthlyPrice": {
      const percentagePropertyName = propertyName + "Percent";
      const { amount, percentage } = calculateDiscountFromAmount(
        prices[propertyName],
        discount[propertyName]
      );

      discount[propertyName] = amount;
      discount[percentagePropertyName] = percentage;

      break;
    }
    case "startPricePercent":
    case "monthlyPricePercent": {
      const amountPropertyName = propertyName.replace(/Percent$/, "");
      const { amount, percentage } = calculateDiscountFromPercentage(
        prices[amountPropertyName],
        discount[propertyName]
      );

      discount[amountPropertyName] = amount;
      discount[propertyName] = percentage;

      break;
    }

    default:
      // do nothing
      return;
  }
}

function cloneDiscountDataOnValueChange(
  currentDiscountData: DiscountData,
  propertyName: string,
  action
) {
  const ret = new DiscountData({
    ...currentDiscountData,
    [propertyName]: action.inputValue
  });

  if (action.changeConfirmed === false) {
    return ret;
  }

  const prices = action.pricesData as PricesData;
  runDiscountDependantPropertyRules(ret, prices, propertyName);

  return ret;
}

export {
  cloneDiscountsState,
  createNewDiscount,
  runDiscountDependantPropertyRules,
  cloneDiscountDataOnValueChange
};
