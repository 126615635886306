import React, { useState, useContext } from "react";
import PricesContext from "./PricesContext";
import DiscountsContext from "./DiscountsContext";
import PriceInput, { PriceInputProps } from "./PriceInput";
import { DiscountData, DiscountDataWithIsNewFlag } from "./DiscountRow";
import { ActionType } from "./Reducers";

type DiscountEditFormProps = {
  discRowIndex: number;
  discountData: DiscountDataWithIsNewFlag;
  dispatch: React.Dispatch<any>;
  onSaveChangesClick: (event) => void;
  onCancelChangesClick: (event) => void;
};

const DiscountEditForm = (props: DiscountEditFormProps) => {
  const [editorToggle, setEditorToggle] = useState(true);
  const {
    discountCampaignIdList,
    discountLengthList,
    euroBonusClassList
  } = useContext(PricesContext);
  const { formatInputName } = useContext(DiscountsContext);

  const isNew = props.discountData.isNew === true;

  const renderRegularEditor =
    (isNew && editorToggle) ||
    (!isNew && !props.discountData.isEuroBonusDiscount());
  const renderEurobonusEditor =
    (isNew && !editorToggle) ||
    (!isNew && props.discountData.isEuroBonusDiscount());

  function buildPriceInputProps(propName: keyof DiscountData): PriceInputProps {
    return {
      name: formatInputName(props.discRowIndex, propName),
      value: props.discountData[propName] as string | number | undefined,
      dispatch: props.dispatch,
      updateActionType: ActionType.CHANGE_VALUE
    };
  }

  function handleEditorToggle(event, regularEditorView) {
    event.preventDefault();

    props.dispatch({
      type: ActionType.DISCOUNT_RESET_NEW,
      discRowIndex: props.discRowIndex,
      bindingId: props.discountData.bindingId,
      init: {
        eurobonusPointClassId: regularEditorView
          ? undefined
          : euroBonusClassList[0][0]
      }
    });

    setEditorToggle(regularEditorView);
  }

  function handleFieldChange(event, isNumberType: boolean = false) {
    props.dispatch({
      type: ActionType.CHANGE_VALUE,
      inputName: event.target.name,
      inputValue: isNumberType ? Number(event.target.value) : event.target.value
    });
  }

  function renderSelectListOptions(
    list: any[],
    value: any,
    additionalItemText: any
  ) {
    const ret = list.map(([id, name]) => (
      <option key={id} value={id}>
        {name}
      </option>
    ));

    if (isNew) {
      ret.splice(
        0,
        0,
        <option key={"-- select --"} value="">
          -- select --
        </option>
      );
    } else {
      if (!list.some(c => c[0] === value)) {
        ret.push(
          <option key={value} value={value || ""}>
            {additionalItemText || "UNKNOWN"} *
          </option>
        );
      }
    }

    return ret;
  }

  return (
    <React.Fragment>
      {isNew && (
        <ul>
          <li>
            <button
              disabled={editorToggle}
              onClick={event => handleEditorToggle(event, true)}
            >
              Regular Discount Editor
            </button>
          </li>
          <li>
            <button
              disabled={!editorToggle}
              onClick={event => handleEditorToggle(event, false)}
            >
              EuroBonus Discount Editor
            </button>
          </li>
        </ul>
      )}

      <div className="discount-editform">
        {renderRegularEditor && (
          <React.Fragment>
            <h3>Regular Discount Editor</h3>

            <fieldset>
              <div>
                <label>Start discount: </label>
                <span style={{ margin: "0px 5px" }}>%</span>
                <PriceInput
                  {...buildPriceInputProps("startPricePercent")}
                  maxValue={100}
                  placeholder={"max. 100%"}
                />
                <span style={{ margin: "0px 5px" }}>=</span>
                <PriceInput {...buildPriceInputProps("startPrice")} />
                <span style={{ margin: "0px 5px" }}>kr</span>
              </div>

              <div>
                <label>Monthly discount: </label>
                <span style={{ margin: "0px 5px" }}>%</span>
                <PriceInput
                  {...buildPriceInputProps("monthlyPricePercent")}
                  maxValue={100}
                  placeholder={"max. 100%"}
                />
                <span style={{ margin: "0px 5px" }}>=</span>
                <PriceInput {...buildPriceInputProps("monthlyPrice")} />
                <span style={{ margin: "0px 5px" }}>kr</span>
              </div>

              <div>
                <label>Name: </label>
                <input
                  type="text"
                  name={formatInputName(props.discRowIndex, "productName")}
                  value={props.discountData.productName}
                  onChange={handleFieldChange}
                  className="input-field"
                />
              </div>
            </fieldset>
          </React.Fragment>
        )}

        {renderEurobonusEditor && (
          <React.Fragment>
            <h3>EuroBonus Discount Editor</h3>

            <fieldset>
              <label>EuroBonus class: </label>
              <select
                name={formatInputName(
                  props.discRowIndex,
                  "eurobonusPointClassId"
                )}
                value={props.discountData.eurobonusPointClassId}
                onChange={e => handleFieldChange(e, true)}
                className="ddl"
              >
                {renderSelectListOptions(
                  euroBonusClassList,
                  Number(props.discountData.eurobonusPointClassId),
                  props.discountData.eurobonusPointClassName
                )}
              </select>
            </fieldset>
          </React.Fragment>
        )}

        <fieldset>
          <legend>Common fields</legend>

          <div>
            <label>Length: </label>
            <select
              name={formatInputName(props.discRowIndex, "pricePeriod")}
              value={props.discountData.pricePeriod}
              onChange={handleFieldChange}
              className="ddl"
            >
              {renderSelectListOptions(
                discountLengthList,
                props.discountData.pricePeriod,
                props.discountData.pricePeriod
              )}
            </select>
          </div>

          <div>
            <label>Note: </label>
            <input
              type="text"
              name={formatInputName(props.discRowIndex, "note")}
              value={props.discountData.note}
              onChange={handleFieldChange}
              className="input-field"
            />
          </div>

          {/* todo: autocomplete */}
          <div>
            <label>Campaign code: </label>
            <select
              name={formatInputName(props.discRowIndex, "campaignId")}
              value={props.discountData.campaignId || ""}
              onChange={e => handleFieldChange(e, true)}
              className="ddl"
            >
              {renderSelectListOptions(
                discountCampaignIdList,
                Number(props.discountData.campaignId),
                props.discountData.campaignCode
              )}
            </select>
          </div>
        </fieldset>

        <p className="discount-editform-buttons">
          <button type="button" onClick={props.onSaveChangesClick}>
            Save
          </button>
          <button type="button" onClick={props.onCancelChangesClick}>
            Cancel
          </button>
        </p>
      </div>
    </React.Fragment>
  );
};

export default DiscountEditForm;
