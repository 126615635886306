import React, { useState, useLayoutEffect, useRef } from "react";

const intFormatter = new Intl.NumberFormat("sv-SE", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const floatFormatter = new Intl.NumberFormat("sv-SE", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

function safeDisplayValue(price: number | string | undefined): string {
  // price == null returns true when the value is null or undefined
  return price == null ? "" : price.toString();
}

function safeDisplayValueSwedishFormat(
  price: number | string | undefined
): string {
  return safeDisplayValue(price).replace(".", ",");
}

function formatPrice(price): string {
  if (price == null) {
    return "";
  } else {
    const str = price.toString().replace(",", ".");
    return str % 1 === 0
      ? intFormatter.format(str)
      : floatFormatter.format(str);
  }
}

export type PriceInputProps = {
  name: string;
  value: number | string | undefined;
  maxValue?: number;
  placeholder?: string;
  dispatch: React.Dispatch<any>;
  updateActionType: any;
};

const PriceInput = ({
  name,
  value,
  maxValue,
  placeholder,
  dispatch,
  updateActionType
}: PriceInputProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const isFirstRender = useRef(true);
  const changeConfirmed = useRef(true);
  const editField = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (!isEditing) {
        const newValue: number | undefined =
          value != null
            ? parseFloat(value.toString().replace(",", "."))
            : undefined;

        if (newValue !== value || changeConfirmed.current === false) {
          changeConfirmed.current = true;

          dispatch({
            type: updateActionType,
            inputName: name,
            inputValue: newValue,
            changeConfirmed: changeConfirmed.current
          });
        }
      } else {
        editField.current!.select();
      }
    }
  }, [isEditing]);

  function toggleIsEditing(): void {
    setIsEditing(!isEditing);
  }

  function handleOnChange(event): void {
    let strValue: string = event.target.value.trim().replace(".", ",");
    if (strValue[0] === ",") {
      strValue = "0" + strValue;
    }

    if (strValue === "" || strValue.match(/^\d+([,](\d{1,2})?)?$/)) {
      if (maxValue && strValue !== "") {
        const parsedValue = parseFloat(strValue.replace(",", "."));
        if (parsedValue > maxValue) {
          strValue = maxValue.toString();
        }
      }

      changeConfirmed.current = false;

      dispatch({
        type: updateActionType,
        inputName: name,
        inputValue: strValue || undefined,
        changeConfirmed: changeConfirmed.current
      });
    }
  }

  return (
    <React.Fragment>
      <input
        type="hidden"
        name={name}
        // note: ASP.NET MVC model binder in SalesWizard expects Swedish number format
        value={safeDisplayValueSwedishFormat(value)}
      />

      {isEditing ? (
        <input
          ref={editField}
          type="text"
          value={safeDisplayValueSwedishFormat(value)}
          onChange={handleOnChange}
          maxLength={10}
          onBlur={toggleIsEditing}
          className="input-field input-field-price"
          placeholder={placeholder}
        />
      ) : (
        <input
          type="text"
          value={formatPrice(value)}
          onFocus={toggleIsEditing}
          readOnly
          className="input-field input-field-ro input-field-price"
          placeholder={placeholder}
        />
      )}
    </React.Fragment>
  );
};

export default PriceInput;
