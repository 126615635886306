import React, { useEffect, useContext } from "react";
import { PricesDataWithFlags } from "./Prices";
import DiscountRow, { DiscountData } from "./DiscountRow";
import DiscountsContext from "./DiscountsContext";
import { ActionType } from "./Reducers";

type DiscountsProps = {
  bindingId: number;
  discounts: DiscountData[];
  discountsDispatch: React.Dispatch<any>;
  pricesData: PricesDataWithFlags;
};

const Discounts = (props: DiscountsProps) => {
  const { isEditFormBusy, discountRowInEditDispatch } = useContext(
    DiscountsContext
  );

  useEffect(() => {
    if (props.pricesData.changeConfirmed) {
      props.discountsDispatch({
        type: ActionType.DISCOUNT_RECALCULATE,
        pricesData: props.pricesData
      });
    }
  }, [props.pricesData]);

  function handleEditorAction(action): void {
    props.discountsDispatch({
      ...action,
      pricesData: props.pricesData
    });
  }

  function handleAddClick(event): void {
    event.preventDefault();

    discountRowInEditDispatch({
      type: ActionType.DISCOUNT_FORM_SHOW,
      discountRowIndex: null
    });

    handleEditorAction({
      type: ActionType.DISCOUNT_NEW,
      bindingId: props.bindingId
    });
  }

  return props.discounts ? (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td>
            {props.discounts.length === 0 ? (
              <span style={{ fontStyle: "italic" }}>No discounts</span>
            ) : (
              <table style={{ width: "100%" }}>
                <tbody>
                  {props.discounts.map((item, index) => {
                    return (
                      <DiscountRow
                        key={index}
                        discRowIndex={index}
                        discountData={item}
                        onEditorAction={handleEditorAction}
                      />
                    );
                  })}
                </tbody>
              </table>
            )}
          </td>
          <td>
            <button onClick={handleAddClick} disabled={isEditFormBusy}>
              Add
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  ) : null;
};

export default Discounts;
