import React, { useState } from "react";
import PriceLinePair, { PriceLinePairProps } from "./PriceLinePair";

const PriceLinePairResetWrapper = (props: PriceLinePairProps) => {
  const [resetCounter, setResetCounter] = useState(0);

  function handleResetClick(): void {
    setResetCounter(resetCounter + 1);
    props.onIsActiveChange();
  }

  return (
    <PriceLinePair
      key={resetCounter}
      {...props}
      onResetClick={handleResetClick}
    />
  );
};

export default PriceLinePairResetWrapper;
