import React from "react";

export type PricesContextType = {
  instalmentPeriodList: number[];
  discountCampaignIdList: [number, string][];
  discountLengthList: [string, string][];
  euroBonusClassList: [number, string][];
  discountRowInEdit: { priceRowIndex: number; discountRowIndex: number } | null;
  discountRowInEditDispatch: React.Dispatch<any>;
};

const PricesContext = React.createContext<PricesContextType>(null!);

export default PricesContext;
