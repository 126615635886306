import React, { useState, useReducer, useContext } from "react";
import PricesContext from "./PricesContext";
import DiscountsContext, { DiscountsContextType } from "./DiscountsContext";
import Prices, { PricesData } from "./Prices";
import Discounts from "./Discounts";
import { DiscountData, DiscountDataWithIsNewFlag } from "./DiscountRow";
import { pricesReducer, discountsReducer } from "./Reducers";

export type PriceLinePairProps = {
  renderAllBindings: boolean;
  hideCostPrices: boolean;
  onIsActiveChange: () => void;

  pairIndex: number;

  bindingId: number;

  // editable
  isActive: boolean;

  regularPriceRowIndex: number;
  costPriceRowIndex: number;

  // editable
  regularPrices: PricesData;
  // editable
  costPrices: PricesData;
  // editable
  discounts: DiscountData[];
};

function formatBindingName(bindingId: number): string {
  return bindingId === 0 ? `${bindingId} (DEFAULT)` : bindingId.toString();
}

const PriceLinePair = (
  props: PriceLinePairProps & { onResetClick: () => void }
) => {
  const [activeState, setActiveState] = useState({
    isActive: props.isActive,
    dirty: false
  });

  const [regularPrices, regularPricesDispatch] = useReducer(pricesReducer, {
    ...props.regularPrices,
    dirty: false
  });

  const [costPrices, costPricesDispatch] = useReducer(pricesReducer, {
    ...props.costPrices,
    dirty: false
  });

  const [discountsBag, discountsDispatch] = useReducer(discountsReducer, {
    dirty: false,
    current: props.discounts,
    dirtyBackup: false,
    backup: props.discounts
  });

  const { discountRowInEdit, discountRowInEditDispatch } = useContext(
    PricesContext
  );

  const discountsContextProviderValue: DiscountsContextType = {
    formatInputName: formatDiscountDataInputName(props.regularPriceRowIndex),
    isEditFormBusy: discountRowInEdit !== null,
    shouldEditFormBeShown: wrapShouldDiscountEditFormBeShown(
      props.regularPriceRowIndex
    ),
    discountRowInEditDispatch: wrapDiscountRowInEditDispatch(
      props.regularPriceRowIndex
    )
  };

  // const nameof = function<T>(name: keyof T) {
  //   return name;
  // };
  // const safePropName = nameof<DiscountData>(propertyName);

  function formatPricesDataInputName(
    rowIndex: number
  ): (propertyName: keyof PricesData | "isActive" | "isCostPrice") => string {
    return propertyName => `productPriceList[${rowIndex}].${propertyName}`;
  }

  function formatDiscountDataInputName(
    rowIndex: number
  ): (discRowIndex: number, propertyName: keyof DiscountData) => string {
    return (discRowIndex, propertyName) =>
      `productPriceList[${rowIndex}].campaignProductPrices[${discRowIndex}].${propertyName}`;
  }

  function wrapShouldDiscountEditFormBeShown(priceRowIndex) {
    return (
      discountRowIndex: number,
      discountData: DiscountDataWithIsNewFlag
    ) => {
      if (discountData.isNew) {
        return true;
      }

      return (
        discountRowInEdit !== null &&
        (discountRowInEdit.priceRowIndex === priceRowIndex &&
          discountRowInEdit.discountRowIndex === discountRowIndex)
      );
    };
  }

  function wrapDiscountRowInEditDispatch(priceRowIndex) {
    return action => discountRowInEditDispatch({ priceRowIndex, ...action });
  }

  function handleIsActiveChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setActiveState({ isActive: event.target.checked, dirty: true });
    props.onIsActiveChange();
  }

  function handleResetClick(event): void {
    event.preventDefault();

    if (window.confirm("Reset price line changes?")) {
      props.onResetClick();
    }
  }

  const shouldBeRendered: boolean =
    props.renderAllBindings || activeState.isActive;

  const isDirty: boolean =
    activeState.dirty ||
    regularPrices.dirty ||
    costPrices.dirty ||
    discountsBag.dirty;

  const resetButtonDisabled: boolean = !(isDirty && discountRowInEdit == null);

  const costPriceRowStyle = {
    display: props.hideCostPrices ? "none" : undefined
  };

  const priceRowColorClass =
    props.pairIndex % 2 === 0 ? "price-row-even" : "price-row-odd";

  const rowSpan = props.hideCostPrices ? undefined : 2;

  return shouldBeRendered ? (
    <React.Fragment>
      {/* REGULAR PRICES ROW */}
      <tr className={`regular-price-row ${priceRowColorClass}`}>
        <td rowSpan={rowSpan}>
          <label style={{ display: "block", cursor: "pointer" }}>
            <input
              type="checkbox"
              name={formatPricesDataInputName(props.regularPriceRowIndex)(
                "isActive"
              )}
              checked={activeState.isActive}
              value={activeState.isActive.toString()}
              onChange={handleIsActiveChange}
            />
            {!activeState.isActive && (
              <input
                type="hidden"
                name={formatPricesDataInputName(props.regularPriceRowIndex)(
                  "isActive"
                )}
                value="false"
              />
            )}
            {formatBindingName(props.bindingId)}
          </label>

          <input
            type="hidden"
            name={formatPricesDataInputName(props.regularPriceRowIndex)(
              "isCostPrice"
            )}
            value="false"
          />

          {/* COST PRICE HIDDEN FIELDS BEGIN */}
          <input
            type="hidden"
            name={formatPricesDataInputName(props.costPriceRowIndex)(
              "isActive"
            )}
            value={activeState.isActive.toString()}
          />
          <input
            type="hidden"
            name={formatPricesDataInputName(props.costPriceRowIndex)(
              "isCostPrice"
            )}
            value="true"
          />
          {/* COST PRICE HIDDEN FIELDS END */}
        </td>
        <Prices
          key={props.regularPriceRowIndex}
          formatInputName={formatPricesDataInputName(
            props.regularPriceRowIndex
          )}
          prices={regularPrices}
          pricesDispatch={regularPricesDispatch}
        />
        <td rowSpan={rowSpan}>
          <DiscountsContext.Provider value={discountsContextProviderValue}>
            <Discounts
              key={props.regularPriceRowIndex}
              bindingId={props.bindingId}
              discounts={discountsBag.current}
              discountsDispatch={discountsDispatch}
              pricesData={regularPrices}
            />
          </DiscountsContext.Provider>
        </td>
        <td rowSpan={rowSpan}>
          <button
            className="reset-btn"
            onClick={handleResetClick}
            disabled={resetButtonDisabled}
          >
            Reset
          </button>
        </td>
      </tr>
      {/* COST PRICES ROW */}
      <tr
        style={costPriceRowStyle}
        className={`cost-price-row ${priceRowColorClass}`}
      >
        {/* <td></td> */}
        <Prices
          key={props.costPriceRowIndex}
          formatInputName={formatPricesDataInputName(props.costPriceRowIndex)}
          prices={costPrices}
          pricesDispatch={costPricesDispatch}
        />
        {/* <td></td> */}
        {/* <td></td> */}
      </tr>
    </React.Fragment>
  ) : null;
};

export default PriceLinePair;
