import React, { useContext } from "react";
import PricesContext from "./PricesContext";
import PriceInput, { PriceInputProps } from "./PriceInput";
import { ActionType } from "./Reducers";

type PricesProps = {
  formatInputName: (propertyName: keyof PricesData) => string;

  prices: PricesDataWithFlags;
  pricesDispatch: React.Dispatch<any>;
};

export type PricesDataWithFlags = PricesData & {
  dirty: boolean;
  changeConfirmed?: boolean;
};

export type PricesData = {
  startPrice: number | undefined;
  monthlyPrice: number | undefined;
  instalmentPrice: number | undefined;
  instalmentPeriod: number | undefined;
  minimidebPrice: number | undefined;
};

const Prices = (props: PricesProps) => {
  const { instalmentPeriodList } = useContext(PricesContext);

  function buildPriceInputProps(propName: keyof PricesData): PriceInputProps {
    return {
      name: props.formatInputName(propName),
      value: props.prices[propName],
      dispatch: props.pricesDispatch,
      updateActionType: ActionType.CHANGE_VALUE
    };
  }

  function handleInstalmentPeriodChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    const value = event.target.value
      ? parseInt(event.target.value, 10)
      : undefined;

    props.pricesDispatch({
      type: ActionType.CHANGE_VALUE,
      inputName: event.target.name,
      inputValue: value,
      isFinal: true
    });
  }

  return (
    <React.Fragment>
      <td>
        <PriceInput {...buildPriceInputProps("startPrice")} />
      </td>
      <td>
        <PriceInput {...buildPriceInputProps("monthlyPrice")} />
      </td>
      <td>
        <PriceInput {...buildPriceInputProps("instalmentPrice")} />

        <select
          name={props.formatInputName("instalmentPeriod")}
          value={props.prices.instalmentPeriod}
          onChange={handleInstalmentPeriodChange}
          className="ddl"
        >
          <option />
          {instalmentPeriodList.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </td>
      <td>
        <PriceInput {...buildPriceInputProps("minimidebPrice")} />
      </td>
    </React.Fragment>
  );
};

export default Prices;
