import React, { useState, useEffect, useLayoutEffect, useReducer } from "react";
import PriceLinePairResetWrapper from "./PriceLinePairResetWrapper";
import PricesContext from "./PricesContext";
import { discountRowInEditReducer } from "./Reducers";
import dummyPrices from "../../dummyPrices";
import "./PriceEditor.css";
import { DiscountData } from "./DiscountRow";

// todo: fetch real data
function useFetchPrices() {
  const emptyPairs: any = [];
  const [pairs, setPairs] = useState(emptyPairs);

  const mapRawPricesFn = r => ({
    startPrice: r.startPrice,
    monthlyPrice: r.monthlyPrice,
    instalmentPrice: r.instalmentPrice,
    instalmentPeriod: r.instalmentPeriod,
    minimidebPrice: r.minimidebPrice
  });

  const mapRawDiscountsFn = r => {
    return (r.rawDiscounts || []).map(
      d =>
        new DiscountData({
          ...d,
          bindingId: r.bindingId
        })
    );
  };

  const reduceFn = (acc: any[], _cur, index, array) => {
    if (index % 2 === 0) {
      const slice = array.slice(index, index + 2);
      // check data
      if (
        slice[0].bindingId !== slice[1].bindingId ||
        slice[0].isActive !== slice[1].isActive ||
        slice[0].isCostPrice ||
        !slice[1].isCostPrice
      ) {
        throw new Error("Data error");
      }

      const pair = {
        bindingId: slice[0].bindingId,
        isActive: slice[0].isActive,

        regularPriceRowIndex: index,
        regularPrices: mapRawPricesFn(slice[0]),
        discounts: mapRawDiscountsFn(slice[0]),

        costPriceRowIndex: index + 1,
        costPrices: mapRawPricesFn(slice[1])
      };

      acc.push(pair);
    }

    return acc;
  };

  useEffect(() => {
    const result = dummyPrices.priceLines.reduce(reduceFn, []);
    setPairs(result);
  }, []);

  return [
    pairs,
    setPairs,
    dummyPrices.instalmentPeriodList,
    dummyPrices.discountCampaignIdList,
    dummyPrices.discountLengthList,
    dummyPrices.euroBonusClassList
  ];
}

const PriceEditor = () => {
  const [renderAllBindings, setRenderAllBindings] = useState(true);
  const [hideCostPrices, setHideCostPrices] = useState(true);
  const [allRowsHiddenInfo, setAllRowsHiddenInfo] = useState(false);
  const [
    useEffectTriggerOnIsActiveChange,
    setUseEffectTriggerOnIsActiveChange
  ] = useState(true);

  const [discountRowInEdit, discountRowInEditDispatch] = useReducer(
    discountRowInEditReducer,
    null
  );

  const [
    pairs,
    _setPrices,
    instalmentPeriodList,
    discountCampaignIdList,
    discountLengthList,
    euroBonusClassList
  ] = useFetchPrices();

  useLayoutEffect(() => {
    if (pairs.length) {
      const tbl = document.getElementById("tblPriceEditor") as HTMLTableElement;

      setAllRowsHiddenInfo(tbl.tBodies[0].rows.length === 0);
    }
  }, [renderAllBindings, pairs, useEffectTriggerOnIsActiveChange]);

  function handleRenderAllBindingsChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setRenderAllBindings(event.target.checked);
  }

  function handleRenderCostPricesChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setHideCostPrices(!event.target.checked);
  }

  return (
    <React.Fragment>
      <label>
        <input
          type="checkbox"
          checked={renderAllBindings}
          onChange={handleRenderAllBindingsChange}
        />
        Show all bindings
      </label>

      <label>
        <input
          type="checkbox"
          checked={!hideCostPrices}
          onChange={handleRenderCostPricesChange}
        />
        Show cost prices
      </label>

      <hr />

      <form>
        <table className="price-editor-table" id="tblPriceEditor">
          <thead>
            <tr>
              <th>Binding</th>
              <th>Start price</th>
              <th>Monthly price</th>
              <th>Instalment price & period</th>
              <th>Mindeb price</th>
              <th>Discount</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <PricesContext.Provider
              value={{
                instalmentPeriodList,
                discountCampaignIdList,
                discountLengthList,
                euroBonusClassList,
                discountRowInEdit,
                discountRowInEditDispatch
              }}
            >
              {pairs.map((pair, index) => (
                <PriceLinePairResetWrapper
                  key={index}
                  renderAllBindings={renderAllBindings}
                  hideCostPrices={hideCostPrices}
                  onIsActiveChange={() =>
                    setUseEffectTriggerOnIsActiveChange(
                      !useEffectTriggerOnIsActiveChange
                    )
                  }
                  pairIndex={index}
                  bindingId={pair.bindingId}
                  isActive={pair.isActive}
                  regularPriceRowIndex={pair.regularPriceRowIndex}
                  costPriceRowIndex={pair.costPriceRowIndex}
                  regularPrices={pair.regularPrices}
                  costPrices={pair.costPrices}
                  discounts={pair.discounts}
                />
              ))}
              {allRowsHiddenInfo && (
                <tr>
                  <td colSpan={7}>
                    <p
                      style={{
                        backgroundColor: "cornsilk",
                        fontStyle: "italic"
                      }}
                    >
                      All rows are hidden...
                    </p>
                  </td>
                </tr>
              )}
            </PricesContext.Provider>
          </tbody>
        </table>
      </form>
    </React.Fragment>
  );
};

export default PriceEditor;
